import * as React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, Typography, useScrollTrigger, Slide, IconButton, Menu, Button } from '@mui/material';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { AccountCircle } from '@material-ui/icons';
import { MenuItem } from '@material-ui/core';

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const HomeButton = ({ location, history, shouldPollNextAppointment }) => {
  const handleNavigateHome = () => {
    //console.log("current location state: " + JSON.stringify(location.state))
    if (location.state && location.state.scheduleURL) {
      history.push({
        pathname: '/',
        state: { shouldPollNextAppointment: true },
      });
    } else {
      history.push('/');
    }
  };

  return (
    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
      <Button
        style={{
          color: 'white',
          fontSize: '20px',
          textTransform: 'none',
        }}
        onClick={handleNavigateHome}
      >
        Aware Health
      </Button>
    </Typography>
  );
};

const AccountButton = ({ history, location }) => {
  const handleLogout = e => {
    e.preventDefault();
    history.push('/signin');
  };
  const handleProfile = e => {
    e.preventDefault();
    console.log('nav to settings/profile');
    history.push('/settings/profile');
  };
  const handleMenu = e => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleProfile}>My Account</MenuItem>
        <MenuItem onClick={() => window.open('https://www.awarehealth.io/terms', '_blank')}>Terms of Service</MenuItem>
        <MenuItem onClick={() => window.open('https://www.awarehealth.io/privacy', '_blank')}>Privacy Policy</MenuItem>
        <MenuItem onClick={handleLogout}>Log Out</MenuItem>
      </Menu>
    </div>
  );
};

function ButtonAppBar({ shouldPollNextAppointment = false, window }) {
  //console.log("shoudlPollAppointment in button app bar: " + shouldPollNextAppointment)
  const history = useHistory();
  const location = useLocation();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <HideOnScroll window={window}>
        <AppBar
          style={{
            backgroundColor: '#5b92e5',
          }}
          position="static"
        >
          <Toolbar>
            <HomeButton history={history} location={location} shouldPollNextAppointment={shouldPollNextAppointment} />
            <AccountButton history={history} location={location} />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </Box>
  );
}

export default withRouter(ButtonAppBar);
