import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// use this helper to push events to HubSpot so we can centrally
// control which environments should be tracked
export const pushToHubSpot = events => {
  // Skip if not in production
  // if (!process.env.REACT_APP_API_URL?.includes('api.awarehealth.io')) {
  //   console.log(`HubSpot events NOT tracked on non-production environment ${process.env.REACT_APP_API_URL}`, events);
  //   return;
  // }

  // Skip if HubSpot isn't loaded yet
  if (!window.hasOwnProperty('_hsq')) return;

  // Push each event to HubSpot
  events.forEach(event => {
    window._hsq.push(event);
  });
  console.log('HubSpot events:', events);
};

export function HubSpotPageTracker() {
  const location = useLocation();

  useEffect(() => {
    const path = window.location.href;
    pushToHubSpot([['setPath', path], ['trackPageView']]);
  }, [location]);

  return null;
}
